import { __decorate } from "tslib";
// Watch
import { Component, Vue } from 'vue-property-decorator';
import { userToolStore, ticketStore } from '@/store';
import TicketCriarComponent from '@/components/TicketCriarComponent.vue';
import TicketVisualizarComponent from '@/components/TicketVisualizarComponent.vue';
import ShowPopup from '@/components/ShowPopup.vue';
let VeiculoList = class VeiculoList extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.search = '';
        this.selectedItem = {};
        this.tab = 'tab_duvidas';
        this.ticketCriarDialog = false;
        this.ticketVisualizarDialog = false;
        this.ticketVisualizarId = null;
        this.categoriesLabel = {
            SUGESTAO: 'Sugestão',
            PROBLEMA: 'Problema',
            DUVIDA: 'Dúvida',
            ELOGIO: 'Elogio',
        };
    }
    get allowView() {
        if (userToolStore.userToolsIndexed.Suporte) {
            return userToolStore.userToolsIndexed.Suporte[0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Suporte) {
            return userToolStore.userToolsIndexed.Suporte[0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Suporte) {
            return userToolStore.userToolsIndexed.Suporte[0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Suporte) {
            return userToolStore.userToolsIndexed.Suporte[0].allow_delete;
        }
    }
    get tickets() {
        return ticketStore.tickets;
    }
    async tabTicketOnClick(ref) {
        this.loading = true;
        await ticketStore.getTickets();
        this.loading = false;
    }
    async novoTicket() {
        this.ticketCriarDialog = true;
    }
    async visualizarTicket(id) {
        this.ticketVisualizarDialog = true;
        this.ticketVisualizarId = id;
        const form = this.$refs.showTicketVisualizar;
        form.show();
    }
    onCancel() {
        this.ticketCriarDialog = false;
        this.ticketVisualizarDialog = false;
    }
};
VeiculoList = __decorate([
    Component({
        components: {
            TicketCriarComponent,
            TicketVisualizarComponent,
            ShowPopup,
        },
    })
], VeiculoList);
export default VeiculoList;
