import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import _ from 'lodash';
import { ticketStore, toolStore } from '@/utils/store-accessor';
import ClickableCard from '@/components/ClickableCard.vue';
let TicketCriarComponent = class TicketCriarComponent extends Vue {
    constructor() {
        super(...arguments);
        this.valorRetirada = 0;
        this.observacao = '';
        this.loading = false;
        this.propertyToShowDetails = [];
        this.subject = '';
        this.content = '';
        this.falarSobreModulo = false;
        this.moduloSelected = false;
        this.categoryOptions = [
            {
                title: 'Relatar um problema',
                id: 'PROBLEMA',
                subtitle: 'Encontrou um problema no Drive? Corre aqui que a gente te ajuda!',
            },
            {
                title: 'Tirar uma dúvida',
                id: 'DUVIDA',
                subtitle: 'Possui uma dúvida que não foi respondida no FAQ? Pergunte aqui!',
            },
            {
                title: 'Dar uma sugestão',
                id: 'DUVIDA',
                subtitle: 'Possui alguma sugestão de melhoria ou nova funcionalidade? Fala pra gente!',
            },
            {
                title: 'Fazer um elogio',
                id: 'ELOGIO',
                subtitle: 'Sim, nós também gostamos de ouvir elogios! Conte aqui o que você mais gosta no Drive',
            },
        ];
    }
    get modulosOptions() {
        return _.filter(toolStore.tools, (item) => item.school_tool);
    }
    onCancel() {
        this.loading = false;
        this.$emit('on-cancel');
    }
    async criarTicket() {
        this.loading = true;
        await ticketStore.createTicket({
            content: this.content,
            subject: this.subject,
            category: this.propertyToShowDetails[1].id,
        });
        this.$emit('on-cancel');
    }
    async mounted() {
        toolStore.getTools();
    }
};
TicketCriarComponent = __decorate([
    Component({
        components: {
            ClickableCard,
        },
    })
], TicketCriarComponent);
export default TicketCriarComponent;
